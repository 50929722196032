import { Component, ChangeDetectionStrategy, ChangeDetectorRef, ViewChild } from '@angular/core';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import { saveAs } from 'file-saver';

@Component({
  selector: 'app-allocate',
  templateUrl: './allocate.component.html',
  styleUrls: ['./allocate.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AllocateComponent {

  optionsCompleted = false;
  optionsRunning = false;
  studentsCompleted = false;
  studentsRunning = false;
  optionsFileMessage = 'Load options file...';
  studentFileMessage = 'Load student file...';
  optionsDataTitle = 'E.G.';
  studentsDataTitle = 'E.G.';
  numberOfChoices = 0;
  reallocationList = [];
  choicesComplete = 0;
  allocationNumbers = [];
  allocationComplete = false;
  runMessages = [
  ]

  oRows = [
    { "name": "Medical Society", "spaces": 22 },
    { "name": "X Games Club", "spaces": 8 },
    { "name": "Experimentor Sciencing", "spaces": 12 }
  ];

  sRows = [
    { "name": "Jonny Bingo", "c1": "Medical Society", "c2": "X Games Club", "c3": " Sciencing", "c4": "Gardening club", "previousAllocation": 3 },
    { "name": "Fiona Fuller", "c1": "Gardening club", "c2": "Medical Society", "c3": "Car Washing", "c4": "Judo Club", "previousAllocation": 1 },
    { "name": "Ben Johnson", "c1": "X Games Club", "c2": "Sciencing", "c3": "Medical Society", "c4": "Car Washing", "previousAllocation": 2 },
  ];

  sColumns = [
    { "name": 'Name', "prop": 'name'},
    { "name": 'Choice 1', "prop": 'c1'},
    { "name": 'Choice 2', "prop": 'c2'},
    { "name": 'Choice 3', "prop": 'c3'},
    { "name": 'Choice 4', "prop": 'c4'},
    { "name": 'Previous', "prop": 'previousAllocation'}
  ];

  workingORows = [];
  workingSRows = [];
  
  @ViewChild(DatatableComponent, { static: true }) table: DatatableComponent;

  /**
   * Constructor
   *
   * @param NgbModal  modal;
   * @param Renderer2  _renderer
   */
  constructor(private cd: ChangeDetectorRef) { }

  /**
   * Upload Options CSV
   *
   * @param event     
   * Select file;
   */
  previewOptions(event) {
    this.optionsFileMessage = 'Uploading and checking file. Please wait....';
    this.optionsRunning = true;
    const reader = new FileReader();
    var optionsLoaded = [];
    reader.onload = (e: any) => {
      var currentFileContents = e.target.result;
      var uploadBylines = currentFileContents.split('\n');
      uploadBylines.forEach(element => {
          var thisRow = element.split(',');
          optionsLoaded.push({
            "name": thisRow[0].replace(/"/g, '').replace(/ï»¿/g, ''),
            "spaces": thisRow[1].replace(/"/g, '').replace(/\r/g, '')
          });
      });
      this.oRows = optionsLoaded;
      this.optionsCompleted = true;
      this.optionsRunning = false;
      this.optionsFileMessage = 'Options file uploaded.';
      this.optionsDataTitle = 'Your options data';
      this.cd.detectChanges();
    };
    reader.readAsBinaryString(event.target.files[0]);
  }

  /**
   * Upload Student CSV
   *
   * @param event   
   * Select file;
   */
  previewStudents(event) {
    this.studentFileMessage = 'Uploading and checking file. Please wait....';
    this.studentsRunning = true;
    const reader = new FileReader();
    var loadedStudents = [];
    var columnsStudents = [];
    reader.onload = (e: any) => {
      var currentFileContents = e.target.result;
      var uploadBylines = currentFileContents.split('\n');
      uploadBylines.forEach((element, index) => {
          element = element.replace(',', '|');          
          var thisRow = element.split(',');
          var thisChoices = [];
          for (var c=1;c<thisRow.length-1;c++){
            thisChoices.push(thisRow[c].replace(/"/g, ''));
          }          
          if (index === 0) {
            columnsStudents.push({ "name": 'Name', "prop": 'name'});
            this.numberOfChoices = thisChoices.length;
          }
          var newPupil = {
            "name": thisRow[0].replace(/"/g, '').replace(/ï»¿/g, '').replace("|", ","),
          }
          for (c=0; c<thisChoices.length; c++) {
            var cIndex = c+1;
            var cName = 'c' + cIndex;
            var cNameFull = 'Choice ' + cIndex;
            newPupil[cName] = thisChoices[c];
            if (index === 0) {
              columnsStudents.push({ "name": cNameFull, "prop": cName});
            }
          }
          if (index === 0) {
            columnsStudents.push({ "name": 'Previous', "prop": 'previousAllocation'});
            this.numberOfChoices = thisChoices.length;
          }
          newPupil["previousAllocation"] = thisRow[thisRow.length - 1].replace(/"/g, '');
          loadedStudents.push(newPupil);
      });
      this.runMessages.push("Number of Student choices received: " + this.numberOfChoices);
      this.sColumns = columnsStudents;
      this.sRows = loadedStudents;      
      this.studentsCompleted = true;
      this.studentsRunning = false;
      this.studentFileMessage = 'Student file uploaded.';
      this.studentsDataTitle = 'Your students data';
    };
    reader.readAsBinaryString(event.target.files[0]);
  }

  /**
   * initial allocation of pupils
   *
   * Allocation pupils on pupilList 
   * to their first choice
   */
  allocatePupils() {
    this.workingSRows = structuredClone(this.sRows);
    this.workingORows = structuredClone(this.oRows);
    for (var p=0;p<this.workingSRows.length;p++) {
        for (var o=0;o<this.workingORows.length;o++) {
            if (!this.workingORows[o]["allocations"]) this.workingORows[o]["allocations"]= [];
            if (this.workingSRows[p]['c1'] === this.workingORows[o].name) {
                this.workingORows[o]["allocations"].push(this.workingSRows[p]);
                this.workingSRows[p]["pupilAllocation"] = this.workingORows[o].name;
                this.workingSRows[p]["allocationChoice"] = 1;
                break;
            }
        }
    }
    this.runMessages.push('Initial first choice allocation complete.');
    this.choicesComplete = 1;
  }

  /**
   * adjust allocations of pupils
   *
   * remove excess pupils from lists 
   * with more allocations than spaces
   */
  adjustAllocations(removeAllocations) {
    for (var o=0;o<this.workingORows.length;o++){
      this.workingORows[o].full = false;
      this.workingORows[o].pupilsAllocated = this.workingORows[o].allocations.length;
      this.workingORows[o].difference = this.workingORows[o].allocations.length - this.workingORows[o].spaces;
        if (this.workingORows[o].allocations.length >= this.workingORows[o].spaces) this.workingORows[o].full = true;

        for (var a=0;a<this.workingORows[o].allocations.length;a++) {
          this.workingORows[o].allocations.sort((a,b) => b.previousAllocation - a.previousAllocation);
        }
        if (this.workingORows[o].full && this.workingORows[o].difference > 0 && removeAllocations) {
            var interimArray = this.workingORows[o].allocations.slice(this.workingORows[o].spaces);
            this.workingORows[o].allocations.splice(this.workingORows[o].spaces);
            this.reallocationList = this.reallocationList.concat(interimArray);
            this.workingORows[o].difference = 0;
        } 
    }    
  }

  /**
   * reallocated adjusted pupils
   *
   * Add removed pupils to 
   * new choice list.
   */

  reallocatePupils(level) {
      var levelIndex = level-1;
      for (var r=0;r<this.reallocationList.length;r++){
          //find 2nd and 3rd
          var choiceIndex, choiceFound = false, optionIndex = 0;     
          while (!choiceFound && optionIndex < this.workingORows.length) {
              if (this.workingORows[optionIndex].name === this.reallocationList[r]['c' + level]) {
                  choiceFound = true;
                  choiceIndex = optionIndex;
              }
              optionIndex++;    
          }
          //select option
          this.reallocationList[r].pupilAllocation = this.workingORows[choiceIndex].name;
          this.reallocationList[r].allocationChoice = level;
          this.workingORows[choiceIndex].allocations.push(this.reallocationList[r]);
          this.workingORows[choiceIndex].difference++;
          this.workingORows[choiceIndex].pupilsAllocated++
          if (this.workingORows[choiceIndex].difference >= 0) this.workingORows[choiceIndex].full = true;
      }
      this.choicesComplete++;
      this.reallocationList = [];
  }

  /**
   * Check validity of algorithm
   *
   * @param event   
   * Calculate Final Allocations Numbers
   * for reference
   */
  checkChoiceAllocations() {
    var choiceAllocationNumbers = [];
    for (var ca=0;ca<this.numberOfChoices;ca++){
        var ordinance = '';
        switch (ca + 1) {
          case 1:
            ordinance = 'first';
            break;
          case 2:
            ordinance = 'second';
            break;
          case 3:
            ordinance = 'third';
            break;
          case 4:
            ordinance = 'fourth';
            break;
          case 5:
            ordinance = 'fifth';
            break;
          case 6:
            ordinance = 'sixth';
            break;
          case 7:
            ordinance = 'seventh';
            break;
          case 8:
            ordinance = 'eight';
            break;
          default:
            ordinance = 'next';
        }
        choiceAllocationNumbers.push({
          "total": 0,
          "percent": 0,
          "ordinance": ordinance
        });
    }
    for (var p=0; p<this.workingSRows.length;p++){
      //console.log('choiceAllocationNumbers', choiceAllocationNumbers);
      //console.log('this.workingSRows[p].allocationChoice', this.workingSRows[p].allocationChoice);
      choiceAllocationNumbers[(this.workingSRows[p].allocationChoice - 1)].total++;
    }
    for (var ca=0;ca<this.numberOfChoices;ca++){
      choiceAllocationNumbers[ca].percent = Math.round((choiceAllocationNumbers[ca].total/this.workingSRows.length) * 100);
      console.log
    }
    return choiceAllocationNumbers;
  }  

  /**
   * Allocate Students
   *
   * @param event   
   * Allocate students to choice groups;
   */
  allocate(event) {
    this.runMessages.push('Initial Allocation of Pupils....');
    this.allocatePupils();
    while (this.choicesComplete < this.numberOfChoices) {
      this.runMessages.push('Adjusting overspill....');
      this.adjustAllocations(true);
      this.runMessages.push('Reallocating adjusted....#' +  (this.choicesComplete + 1));
      this.reallocatePupils(this.choicesComplete + 1);
    }
    this.runMessages.push('Final Adjustment Calculations...');
    this.adjustAllocations(false);
    this.allocationNumbers = this.checkChoiceAllocations();
    for (var a=0;a<this.workingORows.length;a++) {
      console.log(this.workingORows[a].name, ':', this.workingORows[a].difference);
      if (this.workingORows[a].difference > 0) {
        console.log(this.workingORows[a].allocations[this.workingORows[a].allocations.length -1]);
      }
    }
    this.allocationComplete = true; 
  }

  /**
   * Download CSV File
   * 
   * @param event
   * Download finalise allocation file
   */
  download(event) {
    var exportPupils = [];
    for (var w=0; w<this.workingSRows.length; w++){
      exportPupils.push({
        "name": this.workingSRows[w].name,
        "allocation": this.workingSRows[w].pupilAllocation,
        "choice": this.workingSRows[w].allocationChoice
      });
    }
    const replacer = (key, value) => value === null ? '' : value; // specify how you want to handle null values here
    const header = Object.keys(exportPupils[0]);
    let csv = exportPupils.map(row => header.map(fieldName => JSON.stringify(row[fieldName], replacer)).join(','));
    csv.unshift(header.join(','));
    let csvArray = csv.join('\r\n');

    var blob = new Blob([csvArray], {type: 'text/csv' })
    var filename = `Allocations_${(new Date().toJSON().slice(0,10))}.csv`
    saveAs(blob, filename);
  }
}