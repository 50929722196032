<div class="page-header logo">
    <img class="brand-logo page-leader" alt="modern admin logo" src="/assets/images/logo/logo-dark-lg.png">
</div>
<div class="card narrow">
    <div class="card-content" aria-expanded="true">
        <div class="card-body">
            <p class="card-text">
                Allocate allows you to quickly allocate students to activities based on their preferences, 
                in the fairest way to give the most students their optimum choice.
            </p>
            <p class="card-text">
                Load up a file with the activity options and a file with the student choices and simply click 'Allocate...'.
            </p>
            <h3>Activity Options</h3>
            <p>
                Activity options should be a CSV file with two columns: the activity name 
                and the number of spaces available for that activity.
            </p>
            <h4>{{ optionsDataTitle }}</h4>
            <ngx-datatable class="bootstrap table-bordered" [limit]="5" [rows]="oRows" [columnMode]="'force'" [headerHeight]="30" [footerHeight]="50" [rowHeight]="30"
            [columns]="[{ name: 'Name', prop: 'name'},
                        { name: 'Spaces', prop: 'spaces'}]">
            </ngx-datatable>
            <div class="custom-file form-group col-12">
                <input type="file" class="custom-file-input" accept='text/csv' (change)="previewOptions($event)" id="optionsFile" name="optionsFile">
                <label [ngClass]="{ fileloaded: optionsCompleted, fileLoading: optionsRunning }" class="custom-file-label">{{ optionsFileMessage }}</label>
            </div>
            <h3>Student Choices</h3>
            <p>
                Student choices should be a CSV file with the first column containing the student name, the next 'X' columns containing the student choices 
                (depending on the number of choices made), and the final column containing which choice the student got last time (i.e. 1 if they got their 
                first choice last time, 2 if they got their second choice last time, etc.).
            </p>
            <h4>{{ optionsDataTitle }}</h4>
            <ngx-datatable class="bootstrap table-bordered" [limit]="5" [rows]="sRows" [columnMode]="'force'" [headerHeight]="30" [footerHeight]="50" [rowHeight]="30"
            [columns]="sColumns">                          
            </ngx-datatable>            
            <div class="custom-file form-group col-12">
                <input type="file" class="custom-file-input" accept='text/csv' (change)="previewStudents($event)" id="studentFile" name="studentFile">
                <label [ngClass]="{ fileloaded: studentsCompleted, fileLoading: studentsRunning }" class="custom-file-label">{{ studentFileMessage }}</label>
            </div>                
        </div>
    </div>
    <div class="card-footer right">
        <div>
            <ul class="left">
                <li *ngFor="let message of runMessages">
                {{ message }}
                </li>
            </ul>
        </div>
        <div>
            <ul class="percentages">
                <li *ngFor="let percent of allocationNumbers" [ngClass]="percent.ordinance">
                    {{ percent.total }} pupils received their {{ percent.ordinance }} choice ({{ percent.percent }}%).
                </li>
            </ul>
        </div>
        <button (click)="allocate($event);" class="btn btn-primary" [disabled]="(!studentsCompleted || !optionsCompleted)">Allocate...</button>
        <button (click)="download($event);" class="btn btn-primary" [disabled]="(!allocationComplete)">Download...</button>
    </div>
</div>